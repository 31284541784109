import AddressStoreCheck from './check_store'
import AddressCheckRule from './check_rule'
import AddressSensitive from './check_sensitive'
import { INPUT_LABEL_STORE, RISK_CONTROL_PAGE } from './config'
import apiReport from 'public/src/pages/common/apiReport'
import { isObject, isNullOrUnDef } from '@shein/common-function'
import verifyManager from '@shein-risk/si_verify'
import schttp from 'public/src/services/schttp'
import { Toast } from '@shein/sui-mobile'
import { convertAddress } from 'public/src/pages/components/address/config'

export default {
  data() {
    return {
      fieldRequired: {
        fname: true,
        lname: true,
        fatherName: false,
        englishName: false,
        tel: true,
        standbyTel: false,
        taxNumber: false
      },
      // 敏感词提示
      sensitiveNote: {
        fname: '',
        lname: '',
        fatherName: '',
        englishName: '',
        tel: '',
        standbyTel: ''
      },
      riskIdForKafka: '',
      isAddressCheckRuleGray: false,
      addressSensitiveCheck: null,
      beforeEditFormData: {}
    }
  },
  computed: {
    // 是否需要风控
    isNeedRiskControl() {
      return Boolean(
        this.storeInitData?.storeAbt?.params?.ChangeOrderAddressRisk?.param
          ?.ChangeOrderAddressRiskSwitch === 'on' &&
          Object.keys(RISK_CONTROL_PAGE).find(riskItem =>
            RISK_CONTROL_PAGE[riskItem].includes(
              this.extraData?.riskData?.pageType || ''
            )
          ) &&
          this.extraData?.riskData?.order_no
      )
    }
  },
  methods: {
    async getAddressCheckRule() {
      this.isAddressCheckRuleGray = false
      const form = {
        distribution_type: '2',
        country_id: this.countryId
      }
      const res = this.storeInitData?.storeCheckRule
      const { code = '0', info = {} } = res || {}
      if (code == '0' && info?.result?.isGray === 1) {
        this.isAddressCheckRuleGray = true
        const {
          field_rule_check: fieldRuleCheck = [],
          other_check: otherCheck = []
        } = info?.result || {}
        this.addressCheck = new AddressCheckRule(
          this,
          { fieldRuleCheck, otherCheck },
          this.language
        )
        this.addressCheck?.initRuleConfig()
      } else {
        this.addressCheck = new AddressStoreCheck(this, this.language)
      }
      // 客户端日志，返回正则为空
      if (
        info?.result?.isGray !== 1 ||
        !info?.result?.field_rule_check?.length
      ) {
        apiReport.report({
          apiPath: 'addressbookFieldRuleCheck',
          requestParams: JSON.stringify({
            code,
            info,
            form
          })
        })
      }
    },
    async getSensitiveCheckRule() {
      this.addressSensitiveCheck = null
      const res = this.storeInitData?.storeSensitiveList
      const { code = '0', info = {} } = res || {}
      if (code == '0' && isObject(info?.rules?.[0])) {
        this.addressSensitiveCheck = new AddressSensitive(
          this,
          info?.rules?.[0]
        )
      }
    },

    // 风控初始化
    ininRiskControl() {
      if (!schttp) return
      verifyManager.init({
        axiosInstance: schttp
      })
      this.beforeEditFormData = this.getData()
    },
    /** 核身操作 */
    handleCheckIdentity(checkIdentityData, formData) {
      if (!checkIdentityData) {
        this.isSaveLoading = false
        return
      }

      verifyManager.doVerify(checkIdentityData, async data => {
        const { isSuccess, info } = data
        if (isSuccess) {
          try {
            const result = await schttp({
              method: 'POST',
              url: '/api/user/addressbook/address/risk/get',
              data: {
                ...formData,
                'uberctx-validate-token': info?.validate_token || ''
              }
            })
            this.isSaveLoading = false
            if (result?.info?.risk_decision === 3610) {
              Toast(this.language.SHEIN_KEY_PWA_31531)
              return
            }
            await this.postSubmitData()
          } catch (error) {
            this.isSaveLoading = false
            await this.postSubmitData()
          }
        } else {
          this.isSaveLoading = false
          console.error('校验失败', info)
        }
      })
    },
    /** 风控操作 */
    async handleRiskControl() {
      if (!this.isNeedRiskControl) {
        return true
      }

      this.isSaveLoading = true
      const riskData = this.extraData?.riskData || {}

      const biz_info = {}
      Object.keys(riskData).forEach(key => {
        if (!isNullOrUnDef(riskData[key]) && key !== 'pageType') {
          biz_info[key] = riskData[key]
        }
      })
      biz_info.scene_type =
        Number(
          Object.keys(RISK_CONTROL_PAGE).find(key =>
            RISK_CONTROL_PAGE[key].includes(riskData?.pageType || '')
          )
        ) || ''

      biz_info.original_shipping_address = {
        address_type: 1,
        ...(this.beforeEditFormData || {})
      }
      biz_info.shipping_address = {
        address_type: 1,
        ...(this.getData() || {})
      }
      const formData = {
        biz_id: `${new Date().getTime()}_${riskData?.order_no || ''}`,
        scene: 'change_address',
        source_sys: 'business-address',
        biz_info
      }

      try {
        const result = await schttp({
          method: 'POST',
          url: '/api/user/addressbook/address/risk/get',
          data: formData
        })
        if(result?.code === 0 && result?.info?.risk_id) {
          this.riskIdForKafka = result?.info?.risk_id
        }
        if (result?.info?.risk_decision === 3610) {
          this.isSaveLoading = false
          Toast(this.language.SHEIN_KEY_PWA_31531)
          return false
        }
        if (result?.info?.risk_decision === 9999) {
          this.handleCheckIdentity(result?.info?.extend_info, formData)
          return false
        }
        this.isSaveLoading = false
        return true
      } catch (error) {
        this.isSaveLoading = false
        return true
      }
    },
    getFieldLabel(type) {
      const isRequired = this.fieldRequired[type]
      const action = INPUT_LABEL_STORE.call(this, { isRequired, type })
      return typeof action === 'function' ? action.call(this) : action
    },
    async getOrderAddress(params) { 
      const { billno, countryId } = params
      if (!billno || !countryId) return {}
      if (gbCommonInfo?.MERGE_ORDER_ADDRESS_COUNTRY?.includes(String(countryId))) {
        const res = await schttp({
          method: 'POST',
          url: '/api/orders/base/queryAddressInfo/query',
          data: {
            billno
          }
        })
        if (res?.code == 0) {
          return convertAddress({ 
            from: res?.info?.shipping,
            type: 'shippingToEdit'
          }) || {}
        }
      }
      return {}
    }
  }
}
