
/**
 * author : cuijiaqi
 * email  : cuijiaqi@shein.com
 */


import { validateCpf, validateCnpj, artithmeticNationalIdCheck } from '../config'
import { LoginPatterRegex } from 'public/src/pages/login/util.js'

class AddressCheckRule {

  // 校验字段必填性
  fieldRequired = {
    country: true,
    fname: true,
    lname: true,
    middleName: false,
    fatherName: false,
    englishName: false,
    state: true,
    city: true,
    district: false,
    street: false,
    taxNumber: false,
    address1: true,
    address2: true,
    nationalId: false,
    postcode: true,
    tel: true,
    standbyTel: false,
    zone: true,

    passportNumber: false,
    passportIssuePlace: false,
    passportIssueDate: false,
    birthday: false
  }
  // 校验函数和后端字段映射
  #fieldNamesMap = {
    country: 'country',
    first_name: 'fname',
    last_name: 'lname',
    middle_name: 'middleName',
    father_name: 'fatherName',
    english_name: 'englishName',
    state: 'state',
    city: 'city',
    district: 'district',
    street: 'street',
    tax_number: 'taxNumber',
    address1: 'address1',
    address2: 'address2',
    postcode: 'postcode',
    tel: 'tel',
    standby_tel: 'standbyTel',
    national_id: 'nationalId',

    passport_number: 'passportNumber',
    passport_issue_place: 'passportIssuePlace',
    passport_issue_date: 'passportIssueDate',
    birthday: 'birthday',
    
    store_type_code: 'store_type_code'
  }

  // 特殊规则校验提示文案
  otherCheckCopywriter = {}

  // 联合校验字段映射
  #unionChecks = {
    lname: 'fname',
    address2: 'address1'
  }

  constructor(addressObj, rules) {
    this.addressObj = addressObj
    const { fieldRuleCheck = [], otherCheck = [] } = rules
    this.#setDefaultCheck()
    this.#setCheckMethods(fieldRuleCheck)
    this.#getOtherCheckCopywriter(otherCheck)
  }

  // 获取特殊校验文案
  #getOtherCheckCopywriter(otherCheck) {
    for (let i = 0; i < otherCheck.length; i++) {
      const copywriterList = otherCheck[i].copywrite_list
      for(let j = 0; j < copywriterList.length; j++) {
        const { error_type, copywrite } = copywriterList[j]
        this.otherCheckCopywriter[error_type] = copywrite
      }
    }
  }

  // 获取地址组件值
  #getFieldValue(name) {
    return name === 'taxNumber' && this.addressObj?.getTaxNumber ? this.addressObj.getTaxNumber() : this.addressObj[name]
  }

  #getCheckValue({ fieldName = '', is_union_check = 0, value = this.#getFieldValue(fieldName) }) {
    const isUnionCheckField = Object.keys(this.#unionChecks).includes(fieldName)
    const isUnionCheck = isUnionCheckField && is_union_check === 1
    return isUnionCheck ? value + this.#getFieldValue(this.#unionChecks[fieldName]) : value
  }

  // 初始化校验函数默认值
  #setDefaultCheck() {
    const fields = Object.values(this.#fieldNamesMap )
    for (let i = 0; i < fields.length; i++) {
      const fieldName = fields[i]
      AddressCheckRule[fieldName] = (params = {}) => {
        const { type = fieldName, instance = this.addressObj } = params
        return this.setAddressErrorValue({
          instance,
          type,
          flag: true 
        })
      }
    }
  }
  /**
   * 
   * 初始化校验函数
   * 1、必填校验
   * 2、联合校验
   * 3、正校验
   * 4、反校验
   * 默认是地址组件，但也适用自定义组件
   * @param {Boolean} isShowError 是否展示报错 
   * @param {String} type 报错字段
   * @param {String|Number} value 字段值
   * @param {Object} instance 报错组件
   * @param {String} fakeType 前端报错字段类型
   * @param {Boolean} isFakeRequire 前端校验必填
   * @param {String} fakeRequiredCopywriter 前端校验必填提示文案
   * 
   * 
  */
  #setCheckMethods(fieldRuleCheck) {
    // 初始化校验函数
    for(let i = 0; i < fieldRuleCheck.length; i++) {
      const { 
        field_name = '', is_require = '0', require_copywrite = '',
        regex_list = [], reverse_regex_list = [],
      } = fieldRuleCheck[i]
      const fieldName = this.#fieldNamesMap[field_name]

      // 配置必填
      this.fieldRequired[fieldName] = (is_require === '1')

      AddressCheckRule[fieldName] = (params = {}) => {
        const { 
          isShowError = true, type = fieldName,
          instance = this.addressObj, fakeType = '',
          isFakeRequire = false, fakeRequiredCopywriter = '',
          value = this.#getCheckValue({ fieldName })
        } = params
        try {
          // 必填校验
          if ((is_require === '1' || isFakeRequire) && !value) {
            isShowError && this.setAddressErrorValue({
              instance,
              text: fakeRequiredCopywriter || require_copywrite,
              type: fakeType || type,
              flag: false
            })
            return false
          }

          // 正正则
          for(let j = 0; j < regex_list.length; j++) {
            const { reg, copywrite, is_union_check = 0 } = regex_list[j]
            const regExp = new RegExp(reg)
            const checkValue = this.#getCheckValue({ fieldName, is_union_check, value })
            if (checkValue && !regExp.test(checkValue)) {
              isShowError && this.setAddressErrorValue({
                instance,
                text: copywrite,
                type: fakeType || type,
                flag: false
              })
              return false
            }
          }

          // 反正则
          for(let j = 0; j < reverse_regex_list.length; j++) {
            const { reg, copywrite, is_union_check = 0 } = reverse_regex_list[j]
            const regExp = new RegExp(reg)
            const checkValue = this.#getCheckValue({ fieldName, is_union_check, value })
            if (checkValue && regExp.test(checkValue)) {
              isShowError && this.setAddressErrorValue({
                instance,
                text: copywrite,
                type: fakeType || type,
                flag: false 
              })
              return false
            }
          }
          return this.setAddressErrorValue({
            instance,
            type: fakeType || type,
            flag: true 
          })
        } catch (error) {
          console.log('Address check action error!!!', error)
          return this.setAddressErrorValue({
            instance,
            type: fakeType || type,
            flag: true 
          })
        }
      }
    }
  }

  /**
   * 
   * 报错提示函数
   * @param {Object} instance 组件实例 默认地址组件
   * @param {String} text 提示文案
   * @param {String} type 报错类型
   * @param {Boolean} flag 报错展示标志
   * 
   * */ 
  setAddressErrorValue ({ instance = this.addressObj, text = '', type, flag = true } = {}) {
    const error = instance && instance.error && instance.error[type]
    if (error) {
      instance.error[type] = {
        ...error,
        value: text,
        show: !flag,
        flag
      }
    }
    return flag
  }
}

class AddressCheckRuleAdapter extends AddressCheckRule {

  // 组件内校验规则
  #specialChildComponents = ['certificateTw', 'certificatePe', 'certificateVue', 'passportVue', 'certificateKr', 'mapVue']

  constructor(addressObj, rules, language) {
    super(addressObj, rules)
    this.language = language ? language : { postcode: {}, label: {}, tips: {} }
  }

  country () {
    const id = this.addressObj.countryId || ''
    const { countryCheckIsExist, countryCheckIsShield } = this.otherCheckCopywriter
    if (countryCheckIsExist && (!id || id == '0')) {
      return this.setAddressErrorValue({ 
        text: countryCheckIsExist,
        type: 'country',
        flag: false 
      })
    }
    if (countryCheckIsShield) {
      const { countryList = [], commonList = [] } = this.addressObj
      const countryLists = countryList.concat(commonList || [])
      const isShield = countryLists.some(item => {
        return item.value == id && item.isShield === '1'
      })
      if (isShield) {
        return this.setAddressErrorValue({ 
          text: countryCheckIsShield, 
          type: 'country',
          flag: false
        })
      }
    }
    return this.setAddressErrorValue({
      type: 'country',
      flag: true 
    })
  }

  fname() {
    return AddressCheckRule.fname()
  }

  lname() {
    return AddressCheckRule.lname()
  }

  fullName() {
    return this.fname()
  }

  middleName() {
    const { countryId: id, operateType } = this.addressObj
    const isFakeRequire = (id == '186') && (operateType === 'supplement')
    return AddressCheckRule.middleName(isFakeRequire ? { isFakeRequire, fakeRequiredCopywriter: this.language.tips.middleName } : undefined)
  }

  fatherName() {
    return AddressCheckRule.fatherName()
  }

  englishName() {
    return AddressCheckRule.englishName()
  }

  state() {
    const { countryId, noCheckState = [] } = this.addressObj
    const id = countryId + ''
    if (this.#isStoreBillingAddress() || (noCheckState.length && noCheckState.includes(id))) {
      return this.setAddressErrorValue({
        type: 'state',
        flag: true 
      })
    } 
    if (!this.addressObj.keyShow.newState) {
      return AddressCheckRule.state()
    }

    const { state: val } = this.addressObj
    const { stateStatusError, stateCheckExist } = this.otherCheckCopywriter
    if (stateStatusError) {
      const { stateList = [] } = this.addressObj
      const isShield = stateList.length && stateList.some(item => {
        return item.value == val && item.isShield === '1'
      })
      if (isShield) {
        return this.setAddressErrorValue({
          text: stateStatusError,
          type: 'state', 
          flag: false
        })
      }
    }

    if (stateCheckExist) {
      const { addrCache: { _state } } = this.addressObj
      if (_state[id] && _state[id].length) {
        const matchState = _state[id].filter(ele => {
          return ele.name == val
        })
        if (!matchState.length) {
          return this.setAddressErrorValue({
            text: stateCheckExist,
            type: 'state', 
            flag: false
          })
        }
      }
    }
    return AddressCheckRule.state({ fakeRequiredCopywriter: this.language.tips.choose })
  }

  newState() {
    return this.state()
  }

  city() {
    if (!this.addressObj.keyShow.newCity) {
      return AddressCheckRule.city()
    }
    const { countryId, city: val } = this.addressObj
    const id = countryId + ''

    const { cityStatusError, cityCheckExist } = this.otherCheckCopywriter
    if (cityStatusError) {
      const { cityList = [] } = this.addressObj
      const isShield = cityList.length && cityList.some(item => {
        return item.value == val && item.isShield === '1'
      })
      if (isShield) {
        return this.setAddressErrorValue({
          text: cityStatusError,
          type: 'city',
          flag: false 
        })
      }
    }

    if (cityCheckExist) {
      const { state, addrCache: { _city = {} } } = this.addressObj
      const parentId = this.addressObj.getStateSelectedId(id, state)
      const cacheId = id + '_' + parentId
      let matchCity = []
      if (_city[cacheId] && _city[cacheId].length) {
        matchCity = _city[cacheId].filter(ele => {
          return ele.name == val
        })
      }
      if (!matchCity.length) {
        return this.setAddressErrorValue({
          text: cityCheckExist,
          type: 'city',
          flag: false 
        })
      }
    }
    return AddressCheckRule.city({ fakeRequiredCopywriter: this.language.tips.choose })
  }

  newCity() {
    return this.city()
  }

  district() {
    if (!this.addressObj.keyShow.newDistrict) {
      return AddressCheckRule.district()
    }
    const { countryId, district: val } = this.addressObj
    const id = countryId + ''

    const { districtStatusError, districtCheckExist } = this.otherCheckCopywriter
    if (districtStatusError) {
      const { districtList = [] } = this.addressObj
      const isShield = districtList.length && districtList.some(item => {
        return item.value == val && item.isShield === '1'
      })
      if (isShield) {
        return this.setAddressErrorValue({
          text: districtStatusError,
          type: 'district',
          flag: false 
        })
      }
    }

    if (districtCheckExist) {
      const { state, city, addrCache: { _district = {} } } = this.addressObj
      const parentId = this.addressObj.getCitySelectedId(id, state, city)
      const cacheId = id + '_' + parentId
      if (_district[cacheId] && _district[cacheId].length) {
        const matchDistrict = _district[cacheId].filter(ele => {
          return ele.name == val
        })
        if (!matchDistrict.length) {
          return this.setAddressErrorValue({
            text: districtCheckExist,
            type: 'district',
            flag: false 
          })
        }
      }
    }
    return AddressCheckRule.district({ fakeRequiredCopywriter: this.language.tips.choose })
  }

  newDistrict() {
    return this.district()
  }

  street() {
    return AddressCheckRule.street()
  }

  taxNumber() {
    const { countryId, operateType } = this.addressObj
    const id = countryId + ''
    const isFakeRequire = (id == '198') && (operateType === 'supplement')
    const flag = AddressCheckRule.taxNumber(isFakeRequire ? { isFakeRequire } : undefined)

    if (!flag || id !== '30') {
      return flag
    }

    // 巴西税号
    const value = this.addressObj.getTaxNumber()
    const brTaxNumberError = this.otherCheckCopywriter.brTaxNumberError
    if (brTaxNumberError && !validateCpf(value) && !validateCnpj(value)) {
      return this.setAddressErrorValue({
        text: brTaxNumberError,
        type: 'taxNumber',
        flag: false 
      })
    }
    return this.setAddressErrorValue({
      type: 'taxNumber',
      flag: true 
    })
  }

  address1() {
    const { countryId, address1: val, postcode, city } = this.addressObj
    const id = countryId + ''
    if (this.#isStoreBillingAddress()) {
      if (id == '74' && (val.length < 5 || val.length > 144)) {
        const text = this.language.tips.address1.replace('100', '144')
        return this.setAddressErrorValue({
          text,
          type: 'address1',
          flag: false 
        })
      }  
      if (val.length < 1 || val.length > 80) {
        const text = this.language.tips.address1.replace('5', '1').replace('100', '80')
        return this.setAddressErrorValue({
          text,
          type: 'address1',
          flag: false 
        })
      } 
      return this.setAddressErrorValue({
        type: 'address1',
        flag: true 
      })
    }
    
    const flag = AddressCheckRule.address1()
    if (!flag) {
      return flag
    }
    // Poland
    if (id == '172') {
      const { plAddress1Error } = this.otherCheckCopywriter
      if (plAddress1Error) {
        const sameWords = new RegExp(`^(${ city }|${ postcode })([\\s]*(${ postcode }|${ city }))?$`)
        if (sameWords.test(val)) {
          return this.setAddressErrorValue({
            text: plAddress1Error,
            type: 'address1',
            flag: false 
          })
        }
      }
    }
    return this.setAddressErrorValue({
      type: 'address1',
      flag: true
    })
  }

  address2() {
    const { countryId, address2: val, address1, postcode, city } = this.addressObj
    const id = countryId + ''
    if (this.#isStoreBillingAddress()) {
      if (id == '74' && ((val.length + address1.length) > 144)) {
        const text = this.language.tips.address2_74.replace('70', '144')
        return this.setAddressErrorValue({
          text,
          type: 'address2',
          flag: false 
        })
      }
      if ((val.length + address1.length) > 80) {
        const text = this.language.tips.address2_74.replace('70', '80')
        return this.setAddressErrorValue({
          text,
          type: 'address2',
          flag: false 
        })
      }
      return this.setAddressErrorValue({
        type: 'address2',
        flag: true 
      })
    }

    const flag = AddressCheckRule.address2()
    if (!flag) {
      return flag
    }
    // Poland
    if (id == '172') {
      const { plAddress2Error } = this.otherCheckCopywriter
      if (plAddress2Error) {
        const sameWords = new RegExp(`^(${ city }|${ postcode })([\\s]*(${ postcode }|${ city }))?$`)
        if (val && sameWords.test(val)) {
          return this.setAddressErrorValue({
            text: plAddress2Error,
            type: 'address2',
            flag: false 
          })
        }
      }
    }
    return this.setAddressErrorValue({
      type: 'address2',
      flag: true
    })
  }

  postcode(isShowError = true) {
    const postcodeStatusError = this.otherCheckCopywriter.postcodeStatusError
    if (postcodeStatusError) {
      const { postcodeList = [], postcode: val } = this.addressObj
      const isShield = postcodeList.length && postcodeList.some(item => {
        return item.value === val && item.isShield === '1'
      })
      if (isShield) {
        isShowError && this.setAddressErrorValue({
          text: postcodeStatusError,
          type: 'postcode',
          flag: false 
        })
        return false
      }
    }
    if (!this.addressObj.keyShow.newPostcode) {
      return AddressCheckRule.postcode({ isShowError })
    }
    return AddressCheckRule.postcode({ isShowError, fakeRequiredCopywriter: this.language.tips.choose })
  }

  newPostcode() {
    return this.postcode()
  }

  tel() {
    return AddressCheckRule.tel()
  }

  standbyTel() {
    const flag = AddressCheckRule.standbyTel()
    if (!flag) {
      return flag
    }
    const { standbyTel: val = '', tel } = this.addressObj
    if (val && val === tel) {
      return this.setAddressErrorValue({
        text: this.language?.tips?.tel_no_same || this.language?.SHEIN_KEY_PWA_15952,
        type: 'standbyTel',
        flag: false 
      })
    }
    return this.setAddressErrorValue({
      type: 'standbyTel',
      flag: true
    })
  }

  nationalId() {
    const { countryId, operateType, nationalId: val } = this.addressObj
    const id = countryId + ''
    const fakeRequireMap = {
      105: this.language.tips.national_id_il,
      196: '', // 默认使用require_copywrite
      186: this.language.tips?.cetificate_passport_num,
      165: ''
    }
    const isFakeRequire = fakeRequireMap[id] !== undefined && operateType === 'supplement'

    const flag = AddressCheckRule.nationalId(isFakeRequire ? { isFakeRequire, fakeRequiredCopywriter: fakeRequireMap[+id] } : undefined)
    if (!flag || id !== '186') {
      return flag
    }
    const { saNationalidError } = this.otherCheckCopywriter
    if (val && saNationalidError) {
      const { tel = '', standbyTel = '' } = this.addressObj
      const isPass = artithmeticNationalIdCheck({ tel, standbyTel, nationalId: val })
      if (!isPass) {
        return this.setAddressErrorValue({
          text: saNationalidError,
          type: 'nationalId',
          flag: false 
        })
      }
    }
    return this.setAddressErrorValue({
      type: 'nationalId',
      flag: true
    })
  }

  zone() {
    return AddressCheckRule.district({ value: this.addressObj.zone, fakeType: 'zone' })
  }

  // 店配
  storeAddress () {
    const val = this.addressObj.storeAddress || ''
    if (!val) {
      const id = this.addressObj.countryId
      return this.setAddressErrorValue({
        text: id == '209' ? this.language?.SHEIN_KEY_PWA_34801 : this.language.SHEIN_KEY_PWA_31672,
        type: 'storeAddress',
        flag: false 
      })
    }
    if(!this.addressObj.extraRuleFlag) return false
    return this.setAddressErrorValue({
      type: 'storeAddress',
      flag: true 
    })
  }

  // 店配&宅配
  email() {
    const { email: val } = this.addressObj || ''
    if (!val) {
      return this.setAddressErrorValue({
        text: this.language.SHEIN_KEY_PWA_15589 || this.language?.tips?.email_empty,
        type: 'email',
        flag: false 
      })
    }
    if(!LoginPatterRegex.test(val)) {
      return this.setAddressErrorValue({
        text: this.language.SHEIN_KEY_PWA_15331 || this.language?.tips?.email,
        type: 'email',
        flag: false 
      })
    }
    return this.setAddressErrorValue({
      type: 'email',
      flag: true 
    })
  }

  blacklist (type, index = 1) {
    const { 
      stateBlackAddressError = '',
      cityBlackAddressError = '',
      taxusError = '', 
      postcodeExistCheck = '' 
    } = this.otherCheckCopywriter
    const {
      er_blacklsit_1,
      er_blacklsit_2,
      er_blacklsit_6,
      er_blacklsit_7
    } = this.language.tips
    const blackAddressError = (type === 'state') ? stateBlackAddressError : cityBlackAddressError
    const errorKeys = [blackAddressError, er_blacklsit_2, '', taxusError, postcodeExistCheck, er_blacklsit_6, er_blacklsit_7]
    let errorKey = errorKeys[index - 1]
    if (type === 'postcode' && index === 1) {
      errorKey = er_blacklsit_1
    }
    if (errorKey) {
      setTimeout(() => {
        this.setAddressErrorValue({
          text: errorKey,
          type,
          flag: false 
        })
      }, 1000)
    }
  }

  // 账单地址是否为法国店配
  #isStoreBillingAddress () {
    const { countryId, pageType, storeType, eurShopTransit = [] } = this.addressObj
    const id = +countryId
    return pageType === 'billing' && storeType && eurShopTransit.includes(id)
  }

  // 初始化组件校验规则
  #setChildComponentsCheck() {
    this.addressObj.$nextTick(() => {
      for(let i = 0; i < this.#specialChildComponents.length; i++) {
        const type = this.#specialChildComponents[i]
        if (this.addressObj.$refs[type]) {
          this.addressObj.$refs[type].AddressCheckRule = AddressCheckRule
          this.addressObj.$refs[type].fieldRequired = this.fieldRequired
          this.addressObj.$refs[type].otherCheckCopywriter = this.otherCheckCopywriter
        }
      }
    })
  }

  // 设置必填
  #setFieldRequired() {
    this.addressObj.fieldRequired = {
      ...this.addressObj.fieldRequired,
      ...this.fieldRequired
    }
  }

  initRuleConfig() {
    this.#setChildComponentsCheck()
    this.#setFieldRequired()
  }
}

export default AddressCheckRuleAdapter
